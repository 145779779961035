<template>

</template>
<script>
export default {
    mounted(){
        this.$http.get('/clients'+this.$route.fullPath)
          .then(res => {
              this.$router.push(
                  {
                    name: 'auth-login',
                  },
                  () => setTimeout(() => {
                    this.$bvToast.toast(this.$t("You have your email succussfully verified."), {
                      title: this.$i18n.t("Success"),
                      variant: "success",
                      toaster: "b-toaster-top-right",
                    });
                  })
              )
          })
          .catch(error => {
            this.$router.push(
                {
                  name: 'auth-login',
                },
                () => setTimeout(() => {
                  this.$bvToast.toast(this.$t('Email could not verify.'), {
                    title: this.$i18n.t("Error"),
                    variant: "danger",
                    toaster: "b-toaster-top-right",
                  });
                })
            )
          })
    }
}
</script>
